import { useScheduleResource } from "~/resources/useScheduleResource";

export default defineNuxtPlugin( async () => {
  const resource = await useScheduleResource();
  const store = useEventStore();

  async function updateEventStore () {
    store.setItems( await resource.listScheduleItems() );
  }

  await updateEventStore();
} );
