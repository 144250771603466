import { defineStore } from "pinia";
import type { Profile } from "~/resources/useProfileResource";

export const useProfileStore = defineStore( "profile", () => {
  const profile = ref<Profile | null>( null );

  return {
    profile,
  };
} );
