import { objectToCamel, objectToSnake } from "ts-case-convert";
import type { Database } from "~/database.types";
import createSupabaseError from "~/utils/createSupabaseError";

type DbProfileRow = Database["public"]["Tables"]["profiles"]["Row"];
type DbProfileInsert = Database["public"]["Tables"]["profiles"]["Insert"];
type DbProfileUpdate = Database["public"]["Tables"]["profiles"]["Update"];

type Profile = ReturnType<typeof objectToCamel<DbProfileRow>>;
type ProfileInsert = ReturnType<typeof objectToCamel<DbProfileInsert>>;
type ProfileUpdate = ReturnType<typeof objectToCamel<DbProfileUpdate>>;

export async function useProfileResource () {
  const supabase = useSupabaseClient<Database>();

  async function getProfile ( userId: string ): Promise<Profile> {
    const { data, error } = await supabase
      .from( "profiles" )
      .select( "*" )
      .eq( "profile_id", userId )
      .single();

    if ( error ) {
      throw createSupabaseError( error );
    }

    if ( !data ) {
      throw createError( "Unable to get profile data" );
    }

    const profile = objectToCamel( data ) as Profile;

    return profile;
  }

  async function saveProfile ( profile: ProfileInsert ): Promise<Profile> {
    const { data, error } = await supabase
      .from( "profiles" )
      .upsert( objectToSnake( profile ) )
      .select()
      .single();

    if ( error ) {
      throw createSupabaseError( error );
    }

    if ( !data ) {
      throw createError( "Unable to save profile data" );
    }

    const savedProfile = objectToCamel( data ) as Profile;

    return savedProfile;
  }

  return {
    getProfile,
    saveProfile,
  };
}
