export default defineNuxtPlugin( async () => {
  const supabaseUser = useSupabaseUser();

  const resource = await useProfileResource();
  const store = useProfileStore();

  async function updateProfileStore () {
    if ( supabaseUser.value ) {
      store.profile = await resource.getProfile( supabaseUser.value.id );
    }
    else {
      store.profile = null;
    }
  }

  watch( supabaseUser, async () => {
    await updateProfileStore();
  } );

  await updateProfileStore();
} );
