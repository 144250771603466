export default defineNuxtPlugin( () => {
  const headers = useRequestHeaders( ["accept-language"] );
  const acceptLanguage = headers["accept-language"] || "";

  const defaultLanguage = "en-US"; // Fallback language
  const initialLanguage = acceptLanguage.split( "," )[0] || defaultLanguage;

  // Use a Nuxt state to store the user language
  useState<string>( "userLanguage", () => initialLanguage );
} );
