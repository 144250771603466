import { objectToCamel } from "ts-case-convert";
import type { Database } from "~/database.types";
import createSupabaseError from "~/utils/createSupabaseError";

export type ScheduleItem = ReturnType<typeof objectToCamel<Database["public"]["Tables"]["schedule_items"]["Row"]>>;

export async function useScheduleResource () {
  const supabase = useSupabaseClient<Database>();

  async function listScheduleItems (): Promise<ScheduleItem[]> {
    const items = await supabase.from( "schedule_items" )
      .select( "*" );

    if ( items.error ) {
      throw createSupabaseError( items.error );
    }

    if ( !items.data ) {
      throw createError( "Unable to get schedule data" );
    }

    return items.data.map( item => objectToCamel( item ) );
  }

  return {
    listScheduleItems,
  };
}
