export default function useBigDayLock () {
  const locked = ref( true );

  const user = useSupabaseUser();

  if ( user.value?.app_metadata.app_admin ) {
    locked.value = false;
  }

  return readonly( locked );
}
